export const yellow50 = 'rgba(230, 175, 0, 0.5)'
export const yellow = 'rgba(230, 175, 0, 1)'
export const green50 = 'rgba(105, 167, 157, 0.5)'
export const green = 'rgba(105, 167, 157, 1)'
export const grey50 = 'rgba(182, 221, 213, 0.5)'
export const grey = 'rgba(182, 221, 213, 1)'
export const darkGrey50 = 'rgba(98, 99, 102, 0.5)'
export const darkGrey = 'rgba(98, 99, 102, 1)'

export const blueGreenBar = 'rgba(25, 68, 88, 1)'
export const yellowBar = 'rgba(255, 209, 0, 1)'
export const redBar = 'rgba(207, 46, 46, 1)'