import React from 'react';



class SvgSwitch extends React.Component {


    render() {
        return (
            <svg width="63" height="45" viewBox="0 0 63 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_0_1)">
                    <rect x="2" width="61" height="40.9079" rx="5" fill="white" />
                    <rect x="2.5" y="0.5" width="60" height="39.9079" rx="4.5" stroke="#9AADB7" strokeOpacity="0.296818" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M27 10H25V29H27V10ZM31 10H33V29H31V10ZM37 10H39V29H37V10Z" fill="#3B9E93" fillOpacity="0.300385" />
                <defs>
                    <filter id="filter0_d_0_1" x="0" y="0" width="65" height="44.908" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.266667 0 0 0 0 0.345098 0 0 0 0.108441 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                    </filter>
                </defs>
            </svg>

        )
    }
}


export default SvgSwitch;
