import React from 'react'
import ReactDOM from 'react-dom'
import asDrawer from './drawer'
import { Spinner } from './utils'

import { Bar, Line, Pie, defaults } from 'react-chartjs-2'
// import { Chart, PieController, ArcElement, Legend, Tooltip, Title, CategoryScale } from 'chart.js';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import { formatISK, formatPercentage, fractionalSkipRatio, monthlyTooltipLabel, titleCallback, piePercentageTotalCallback } from './utils'
import { blueGreenBar, yellowBar, redBar } from './colors'

import i18nStrings from './i18n'

window.ReactDOM = ReactDOM

let textColor = '#fff'
if (window.chartTextColor) {
	textColor = window.chartTextColor;
}

class PensionCalculatorResults extends React.Component {

  constructor(props) {
    super(props);
  }

	getData(){

		// let start = 67
		let start = this.props.terms.baseAgeOut
		let end = 82 // Todo - should be configurable?

		// Create labels
		let range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);
		let labels = range(start, end)

		// Create datasets
		let datasets = []

		let baseData = new Array(end - start).fill(0);
		for (let i = 0; i < end - start; i++) {
			baseData[i] = this.props.pension.data.regular.expected_monthly_pension
		}

		datasets.push({
			label: i18nStrings.logbundinn_lifeyrir,
			backgroundColor: blueGreenBar,
			data: baseData,
		})

		if (this.props.terms.extraSavingsChecked) {

			let extraData = new Array(end - start).fill(0);
			for (let i = 0; i < this.props.terms.extraPayoutYears; i++) {
				extraData[i] = this.props.pension.data.extra.expected_monthly_payment
			}

			datasets.push({
				label: i18nStrings.sereignarsparnadur,
				backgroundColor: yellowBar,
				data: extraData,
			})
		}

		if (this.props.terms.specialSavingsChecked) {

			let specialData = new Array(end - start).fill(0);
			for (let i = 0; i < this.props.terms.specialPayoutYears; i++) {
				specialData[i] = this.props.pension.data.special.expected_monthly_payment
			}

			datasets.push({
				label: i18nStrings.tilgreind_sereign,
				backgroundColor: redBar,
				data: specialData,
			})
		}
		
		let data = {
			labels: labels,
			datasets: datasets
		}

		return data
	}



  render() {

      return ( 
        
		<div className={`row results position-relative drawer-${this.props.drawer} ${this.props.pension.isFetching ? 'loading' : ''}`}>
			<div className="col-12 p-0">
				<h5 className="mb-48">{i18nStrings.results}</h5>
			</div>
      		{this.props.pension.isFetching &&
     			<Spinner></Spinner> 
        	}
			<div className="col-xl-6 col-12 p-0 text-container">
				{ this.props.terms.baseSavingsChecked && this.props.pension.data.regular && this.props.pension.data.regular.expected_monthly_pension &&
					<>
						<p>{i18nStrings.results1(this.props.terms.baseAgeOut)} <span class="result font-large">{formatISK(this.props.pension.data.regular.expected_monthly_pension)} kr.</span></p>
					</>
				}
				{ this.props.terms.extraSavingsChecked && this.props.pension.data.extra && this.props.pension.data.extra.expected_monthly_payment &&
					<>
						<p>{i18nStrings.results2(this.props.terms.baseAgeOut)}  <span class="result font-large">{formatISK(this.props.pension.data.extra.total_amount_with_interests)} kr.</span></p>
						<p>{i18nStrings.results3(this.props.terms.extraPayoutYears)} <span class="result font-large">{formatISK(this.props.pension.data.extra.expected_monthly_payment)} kr.</span></p>
					</>
				}
				{ this.props.terms.specialSavingsChecked && this.props.pension.data.special && this.props.pension.data.special.expected_monthly_payment &&
					<>
						<p>{i18nStrings.results4(this.props.terms.baseAgeOut)} <span class="result font-large">{formatISK(this.props.pension.data.special.total_amount_with_interests)} kr.</span></p>
						<p>{i18nStrings.results5(this.props.terms.specialPayoutYears)} <span class="result font-large">{formatISK(this.props.pension.data.special.expected_monthly_payment)} kr.</span></p>
					</>
				}
        		<span className="asterisk d-block">{i18nStrings.results_only_show_estimate}</span>
			</div>
			<div className="col-xl-6 p-0 chart-container">

				{ this.props.pension.data.regular && 
				<>
					<div>
						<Bar height={270}
							width={500}
							data={this.getData()} 
							options={{
								animation: false,
								maintainAspectRatio: false,
								responsive: false,
								plugins: {
									legend: {
										position: 'bottom',
										labels: {
											color: textColor,
										}
									},
									tooltip: {
										callbacks: {
											label: monthlyTooltipLabel,
										}
									},
								},
								scales: {
									x:
									{
										stacked: true,
										gridLines: { display: false },
										ticks: {
											color: textColor,
											font: {
												size: 12,
												weight: '700'
											}
										},
										grid: {
											display: false,
										}
									},
									y:
									{
										stacked: true,
										ticks: {
											min: 0,
											color: textColor,
											callback: function (label, index, labels) {
												return `${formatISK(label / 1000)} þ.`;
											},
											font: {
												size: 12,
												weight: '700'
											}
										}
									}
								}
							}} 
						/>
					</div>
				</>
				}
			</div>
		</div>
		)
    }
}

export default asDrawer(PensionCalculatorResults);



