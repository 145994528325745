import React from 'react';
import { connect } from 'react-redux'
import { setState, overwriteState, fetchPension, setMultiState } from '../../redux/actions';
import { formatISK, formatPercentage, buildParams, deparam } from './utils';

var Promise = require('es6-promise').Promise;
import i18nStrings from './i18n'


import Cleave from 'cleave.js/react';

// import PensionCalculatorBaseSavings from './pension-calculator-base-savings'
// import PensionCalculatorSpecialSavings from './pension-calculator-special-savings'
// import PensionCalculatorExtraSavings from './pension-calculator-extra-savings'
import PensionCalculatorResults from './pension-calculator-results'
import SvgSwitch from './svg-switch';

class PensionCalculator extends React.Component {

  constructor(props) {
    super(props);
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    this.specialSavingsToggle = this.specialSavingsToggle.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onPercentageInputChange = this.onPercentageInputChange.bind(this);
    this.onSliderValueInputChange = this.onSliderValueInputChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onBlurMonthlyPay = this.onBlurMonthlyPay.bind(this);
    this.onChangeAgeIn = this.onChangeAgeIn.bind(this);

    this.baseSliderValueInit = this.baseSliderValueInit.bind(this);
    this.specialSliderValueInit = this.specialSliderValueInit.bind(this);


    // Constants for iðgjaldaprósenta and tilgreind
    this.baseSliderValueMax = 15.5;
    this.baseSliderValueMin = 12.0;
    this.specialSliderValueMax = 3.5;
    this.specialSliderValueMin = 0;

    this.state = {
      baseSliderCleave: null,
      specialSliderCleave: null
    }

  }

  componentDidUpdate() {
    window.dispatchEvent(new Event('resize'));
  }


  componentDidMount() {

    // Plug params into terms
    var paramObject = {
      // TODO taka út harðkóðun:
      'ageIn': this.props.aldur,
      'monthlyPay': this.props.laun,
      'baseAgeOut': this.props.taka_lifeyris_hefst,
      'baseCurrentValue': parseInt(parseFloat(this.props.aunnid)),
      'baseSliderValue': parseFloat(this.props.idgjald),
      'baseEmployerRate': 0.115,
      'baseEmployeeRate': 0.04,
      'baseSavingsChecked': true,

      // sereign = extra
      'extraSavingsChecked': this.props.sereign === "True",
      'extraCurrentValue': this.props.sereign_inneign,
      'extraEmployeeRate': parseFloat(this.props.sereign_idgjald), // Choose either extraEmployerRate or extraEmployeeRate and the other becomes 0
      'extraEmployerRate': 0,
      'extraEmployerRate10': 0,
      'extraReturnRate': parseFloat(this.props.avoxtun),
      'extraPayoutYears': this.props.utborgun,

      // tilgreind_sereign = special
      'specialSavingsChecked': this.props.tilgreind_sereign === "True",
      'specialCurrentValue': this.props.tilgreind_sereign_inneign,
      'specialSliderValue': parseFloat(this.props.tilgreind_sereign_prosenta),
      'specialReturnRate': parseFloat(this.props.tilgreind_sereign_avoxtun),
      'specialPayoutYears': this.props.tilgreind_sereign_utborgunartimi,
    }

    let queryParams = window.location.search.replace("?", "")
    if (queryParams !== "") {
      let newTerms =  Object.assign(paramObject, deparam(queryParams, true))
      if (Object.keys(newTerms).includes('baseCurrentValue')){
        newTerms.baseCurrentValue = parseInt(parseFloat(newTerms.baseCurrentValue))
      }
      Promise.resolve(delete newTerms.showResults)
        .then(() => {
          Promise.resolve(this.props.dispatch(overwriteState(newTerms)))
            .then(() => {
              Promise.resolve(this.props.dispatch(setMultiState(newTerms)))
              .then(() => {
                Promise.resolve(this.props.dispatch(fetchPension(buildParams(this.props.terms))))
                  .then(() => {
                    this.props.dispatch(setState({ key: 'showResults', value: true }))
                  })
              })
            })
        })
    }

    else {
      Promise.resolve(this.props.dispatch(setMultiState(paramObject)))
        // .then(()=> { Promise.resolve(this.props.dispatch(setState({ key: 'ageIn', value: this.props.ageIn })))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        })
      // })
    }
  }

  onCheckBoxChange(key, event) {
    Promise.resolve(this.props.dispatch(setState({ key: key, value: event.target.checked })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });
  }

  // Iðgjöld + tilgreind séreign = 15.5%
  // When special savings is checked, we change the percentage for iðgjöld to
  // reflect the added percentage for tilgreind séreign
  specialSavingsToggle(key, event) {
    var baseVal = 0;
    var specialVal = 0;

    if (event.target.checked) {
      baseVal = this.baseSliderValueMin;
      specialVal = this.specialSliderValueMax;
    } else {
      baseVal = this.baseSliderValueMax;
      specialVal = this.specialSliderValueMin;
    }

    let paramObject = {
      'specialSliderValue': specialVal,
      'baseSliderValue': baseVal
    }

    // Change base and special percentage
    Promise.resolve(this.props.dispatch(setMultiState(paramObject)))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      });

    // Toggle checkbox
    this.onCheckBoxChange(key, event)
  }

  onInputChange(key, event) {
    if (event.target.value) {
      var val = event.target.value.replace(/[\.\-]/g, '');
      if (val === "") {
        val = 0;
      }

      console.log("Changing ", key)
      Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        });
    }
  }

  onPercentageInputChange(key, event) {
    if (event.target.value) {
      var val = event.target.value.replace(/\,/g, '.');

      Promise.resolve(this.props.dispatch(setState({ key: key, value: parseFloat(val) })))
        .then(() => {
          console.log("Fetching pension")
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        });
    }
  }

  // Combined percentage for iðgjaldaprósenta and tilgreind séreign cannot exceed 15.5  
  onSliderValueInputChange(key, event) {
    if (event.target.value) {
      var val = parseFloat(event.target.value.replace(/\,/g, '.'));
      var other = 0;
      let paramObject = {
        'specialSliderValue': this.props.terms.specialSliderValue,
        'baseSliderValue': this.props.terms.baseSliderValue,
      }

      // If special is changed, recalculate from those values, else use base
      var [max, min] = key === "specialSliderValue" ? [this.specialSliderValueMax, this.specialSliderValueMin] : [this.baseSliderValueMax, this.baseSliderValueMin];

      val = val > max ? max : val
      val = val < min ? min : val
      other = this.baseSliderValueMax - val;

      if (key === "specialSliderValue") {
        paramObject.specialSliderValue = val;
        paramObject.baseSliderValue = other;
      } else {
        paramObject.specialSliderValue = other;
        paramObject.baseSliderValue = val;
      }

      // For display purposes in Cleave
      this.state.baseSliderCleave.setRawValue(paramObject.baseSliderValue)
      if (this.props.terms.specialSavingsChecked) {
        this.state.specialSliderCleave.setRawValue(paramObject.specialSliderValue)
      }

      Promise.resolve(this.props.dispatch(setMultiState(paramObject)))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        });
    }
  }

  // Helper functions for rendering input value for Cleave correctly
  baseSliderValueInit(cleave) {
    this.setState({ baseSliderCleave: cleave });
  }

  specialSliderValueInit(cleave) {
    this.setState({ specialSliderCleave: cleave });
  }

  handleClick() {
    Promise.resolve(this.props.dispatch(fetchPension(buildParams(this.props.terms))))
      .then(() => {
        this.props.dispatch(setState({ key: 'showResults', value: true }))
      })
  }

  onKeyUp(key, event) {
    if (event.which == 13 || event.keyCode == 13) {
      var val = event.target.value.replace(/[\.\-]/g, '');
      if (val === "") {
        val = 0;
      }
      Promise.resolve(this.props.dispatch(setState({ key: key, value: parseInt(val) })))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        })
    }
  }

  onBlurMonthlyPay(event) {
    var val = event.target.value.replace(/[\.\-]/g, '');
    if (val === "") {
      val = 0;
    }
    Promise.resolve(this.props.dispatch(setState({ key: 'monthlyPay', value: parseInt(val) })))
      .then(() => {
        this.props.dispatch(fetchPension(buildParams(this.props.terms)))
      })
  }

  onChangeAgeIn(event) {
    var val = event.target.value;

    if (val.length == 2) {
      val = parseInt(val)

      if (val > 70) {
        val = 70
      } if (val < 16) {
        val = 16
      }

      Promise.resolve(this.props.dispatch(setState({ key: 'ageIn', value: val })))
        .then(() => {
          this.props.dispatch(fetchPension(buildParams(this.props.terms)))
        })
    }

  }


  render() {
    var baseSliderVal = ("" + this.props.terms.baseSliderValue).replace('.', ',')


    return (
      <div className="container-fluid clamped">
        <div className="row"><h2>{i18nStrings.reikna_lifeyri}</h2></div>
        <div className="row mb-60">
          <div className="col-12 col-lg-6 col-md-10 inner-container">
            <div className="row title-row">
              <div className="col-12 d-flex align-items-center"><h5 className="text-nowrap font-large">{i18nStrings.lifeyrir}</h5></div>
            </div>
            <div className="row align-items-center justify-content-between">
              <label htmlFor="aldur" className="col-6 col-form-label">{i18nStrings.nuverandi_aldur}</label>
              <div className="col-5 position-relative d-flex justify-content-end">
                <Cleave placeholder={i18nStrings.aldur}
                  id="aldur"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: ''
                  }}
                  value={this.props.terms.ageIn}
                  onChange={this.onChangeAgeIn}
                />
                <p>ára</p>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <label htmlFor="laun" className="col-6 col-form-label">{i18nStrings.nuverandi_laun_a_manudi}</label>
              <div className="col-5 position-relative d-flex justify-content-end">
                <Cleave placeholder={i18nStrings.manadarlaun}
                  id="laun"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: '.'
                  }}
                  value={this.props.terms.monthlyPay}
                  onKeyUp={this.onKeyUp.bind(this, 'monthlyPay')}
                  onBlur={this.onBlurMonthlyPay}
                />
                <p>kr.</p>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <label htmlFor="aunnid" className="col-6 col-form-label">{i18nStrings.aunnin_lifeyrisrettindi}</label>
              <div className="col-5 position-relative d-flex justify-content-end">
                <Cleave placeholder="0"
                  id="aunnid"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: '.'
                  }}
                  value={this.props.terms.baseCurrentValue}
                  onKeyUp={this.onKeyUp.bind(this, 'baseCurrentValue')}
                  onBlur={this.onInputChange.bind(this, 'baseCurrentValue')}
                />
                <p>kr.</p>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <label htmlFor="taka_lifeyris_hefst" className="col-6 col-form-label">{i18nStrings.taka_lifeyris_hefst}</label>
              <div className="col-5 position-relative d-flex justify-content-end">
                <Cleave placeholder={i18nStrings.aldur}
                  id="taka_lifeyris_hefst"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: ''
                  }}
                  value={this.props.terms.baseAgeOut}
                  // onKeyUp={this.onKeyUp.bind(this, 'taka_lifeyris_hefst')}
                  onBlur={this.onInputChange.bind(this, 'baseAgeOut')}

                />
                <p>ára</p>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <label htmlFor="idgjald" className="col-6 col-form-label">{i18nStrings.idgjaldaprosenta}</label>
              <div className="col-5 position-relative d-flex justify-content-end">
                <Cleave placeholder=""
                  id="idgjald"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ','
                  }}
                  onInit={this.baseSliderValueInit}
                  value={baseSliderVal}
                  // onKeyUp={this.onKeyUp.bind(this, 'idgjald')}
                  // onBlur={this.onSliderValueInputChange.bind(this, 'baseSliderValue')}
                  onBlur={this.onSliderValueInputChange.bind(this, 'baseSliderValue')}
                />
                <p>%</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-10 inner-container rt-container">
            <div className="row align-items-center justify-content-between">
              <div className="form-check form-switch d-flex justify-content-between">
                <label className="form-check-label d-flex align-items-center" htmlFor="sereign"><h5 className="text-nowrap mb-0 font-large">{i18nStrings.sereignarsparnadur}</h5></label>
                <div className="col-5 p-16 position-relative input-container d-flex justify-content-end pe-0">
                  <div className={`switch-inner position-relative ${this.props.terms.extraSavingsChecked && 'checked'}`}>
                    <SvgSwitch />
                    <input className="form-check-input position-relative" type="checkbox" id="sereign" checked={this.props.terms.extraSavingsChecked} onChange={this.onCheckBoxChange.bind(this, 'extraSavingsChecked')} />
                  </div>
                </div>
              </div>
            </div>
            {this.props.terms.extraSavingsChecked &&
              <>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="sereign_inneign" className="col-6 col-form-label">{i18nStrings.nuverandi_inneign}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder={i18nStrings.inneign}
                      id="sereign_inneign"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: '.'
                      }}
                      value={this.props.terms.extraCurrentValue}
                      onKeyUp={this.onKeyUp.bind(this, 'extraCurrentValue')}
                      onBlur={this.onInputChange.bind(this, 'extraCurrentValue')}
                    />
                    <p>kr.</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="sereign_idgjald" className="col-6 col-form-label">{i18nStrings.idgjaldaprosenta}<small> - {i18nStrings.heildarframlag_special_savings_subtitle}</small></label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder=""
                      id="sereign_idgjald"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ','
                      }}
                      value={("" + this.props.terms.extraEmployeeRate).replace('.', ',')}
                      // onKeyUp={this.onKeyUp.bind(this, 'sereign_idgjald')}
                      onBlur={this.onPercentageInputChange.bind(this, 'extraEmployeeRate')}
                    />
                    <p>%</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="avoxtun" className="col-6 col-form-label">{i18nStrings.aaetlud_raunavoxtun}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder=""
                      id="avoxtun"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                      }}
                      value={("" + this.props.terms.extraReturnRate).replace('.', ',')}
                      // onKeyUp={this.onKeyUp.bind(this, 'avoxtun')}
                      onBlur={this.onPercentageInputChange.bind(this, 'extraReturnRate')}
                    />
                    <p>%</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="utborgun" className="col-6 col-form-label">{i18nStrings.utborgunartimi}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder={i18nStrings.utborgun}
                      id="utborgun"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: ''
                      }}
                      value={this.props.terms.extraPayoutYears}
                      // onKeyUp={this.onKeyUp.bind(this, 'utborgun')}
                      onBlur={this.onInputChange.bind(this, 'extraPayoutYears')}
                    />
                    <p>ár</p>
                  </div>
                </div>
              </>
            }
            <div className="row align-items-center justify-content-between title-row">
              <div className="form-check form-switch d-flex justify-content-between">
                <label className="form-check-label d-flex align-items-center" htmlFor="tilgreind_sereign"><h5 className="text-nowrap font-large mb-0">{i18nStrings.tilgreind_sereign}</h5></label>
                <div className="col-5 p-16 position-relative input-container d-flex justify-content-end pe-0">
                  <div className={`switch-inner position-relative ${this.props.terms.specialSavingsChecked && 'checked'}`}>
                    <SvgSwitch />
                    <input className="form-check-input position-relative" type="checkbox" id="tilgreind_sereign" checked={this.props.terms.specialSavingsChecked} onChange={this.specialSavingsToggle.bind(this, 'specialSavingsChecked')} />
                  </div>
                </div>
              </div>
            </div>
            {this.props.terms.specialSavingsChecked &&
              <>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="tilgreind_sereign_inneign" className="col-6 col-form-label">{i18nStrings.nuverandi_inneign}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder={i18nStrings.inneign}
                      id="tilgreind_sereign_inneign"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: '.'
                      }}
                      value={this.props.terms.specialCurrentValue}
                      onKeyUp={this.onKeyUp.bind(this, 'specialCurrentValue')}
                      onBlur={this.onInputChange.bind(this, 'specialCurrentValue')}
                    />
                    <p>kr.</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="tilgrein_sereign_prosenta" className="col-6 col-form-label">{i18nStrings.tilgreind_sereign}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder=""
                      id="tilgreind_sereign_prosenta"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                      }}
                      onInit={this.specialSliderValueInit}
                      value={("" + this.props.terms.specialSliderValue).replace('.', ',')}
                      // onKeyUp={this.onKeyUp.bind(this, 'tilgreind_sereign_prosenta')}
                      onBlur={this.onSliderValueInputChange.bind(this, 'specialSliderValue')}

                    />
                    <p>%</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="tilgreind_sereign_avoxtun" className="col-6 col-form-label">{i18nStrings.aaetlud_raunavoxtun}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder=""
                      id="tilgreind_sereign_avoxtun"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                      }}
                      value={("" + this.props.terms.specialReturnRate).replace('.', ',')}
                      // onKeyUp={this.onKeyUp.bind(this, 'tilgreind_sereign_avoxtun')}
                      onBlur={this.onPercentageInputChange.bind(this, 'specialReturnRate')}

                    />
                    <p>%</p>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <label htmlFor="tilgreind_sereign_utborgunartimi" className="col-6 col-form-label">{i18nStrings.utborgunartimi}</label>
                  <div className="col-5 position-relative d-flex justify-content-end">
                    <Cleave placeholder={i18nStrings.utborgun}
                      id="tilgreind_sereign_utborgunartimi"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: '.'
                      }}
                      value={this.props.terms.specialPayoutYears}
                      // onKeyUp={this.onKeyUp.bind(this, 'tilgreind_sereign_utborgunartimi')}
                      onBlur={this.onInputChange.bind(this, 'specialPayoutYears')}
                    />
                    <p>ár</p>
                  </div>
                </div>
              </>
            }
          </div>
          <div className="col-12">
            <button className="calc-btn mt-48" onClick={this.handleClick}>{i18nStrings.reikna}</button>
          </div>
        </div>
        <PensionCalculatorResults {...this.props} drawer={this.props.terms.showResults ? 'open' : 'closed'} />
      </div>


    )

  }

};



function mapStateToProps(state) {

  var f = { pension: {}, terms: {} }
  f.pension = (state.pension === undefined) ? {} : state.pension
  f.terms = (state.terms === undefined) ? {} : state.terms
  return f;
}

export default connect(mapStateToProps)(PensionCalculator);



