export default {
    skyldusparnadur: gettext('Skyldusparnaður'),
    logbundinn_lifeyrir: gettext('Ellilífeyrir'),
    nuverandi_rettindi: gettext('Núverandi réttindi'),
    nuverandi_rettindi_title: gettext('krónur á mánuði miðað við 67 ára'),
    heildarframlag: gettext('Heildarframlag'),
    heildarframlag_title: gettext('Allt að 14% frá 1. júlí 2017 en 15,5% frá 1. júlí 2018'),
    samtrygging: gettext('Samtrygging'),
    tilgreind_sereign: gettext('Tilgreind séreign'),
    aldur_vid_byrjun_uttektar: gettext("Aldur við byrjun úttektar"),
    vaent_avoxtun: gettext("Vænt ávöxtun"),
    nuverandi_inneign: gettext("Núverandi inneign"),
    nuverandi_aldur: gettext("Núverandi aldur"),
    framlag_launagreidanda: gettext("Framlag launagreiðanda"),
    eigid_framlag: gettext("Eigið framlag"),
    utborgunartimi: gettext("Útborgunartími"),
    results1: (ageOut) => interpolate(
        gettext("Mánaðarlegur, ævilangur ellilífeyrir við %s ára aldur "),
        [ageOut]
    ),
    results2: (ageOut) => interpolate(
        gettext("Inneign í séreignarsjóði við %s ára aldur"),
        [ageOut]
    ),
    results3: (payoutYears) => interpolate(
        gettext("Mánaðarleg útborgun úr séreignarsjóði í %s ár"),
        [payoutYears]
    ),
    results4: (ageOut) => interpolate(
        gettext("Tilgreind séreign við %s ára aldur "),
        [ageOut]
    ),
    results5: (payoutYears) => interpolate(
        gettext("Mánaðarleg útborgun tilgreindrar séreignar í %s ár"),
        [payoutYears]
    ),
    results: gettext("Niðurstöður"),
    results_only_show_estimate: gettext("Athugið að niðurstöður sýna eingöngu áætlun."),
    heildarframlag_special_savings_title: gettext("Allt að 2% frá 1. júlí 2017 en 3,5% frá 1. júlí 2018"),
    lifeyrir: gettext("Ellilífeyrir"),
    reikna_lifeyri: gettext("Reikna lífeyri"),
    nuverandi_laun_a_manudi: gettext("Núverandi laun á mánuði"),
    aunnin_lifeyrisrettindi: gettext("Áunnin lífeyrisréttindi"),
    taka_lifeyris_hefst: gettext("Taka lífeyris hefst"),
    idgjaldaprosenta: gettext("Iðgjaldaprósenta"),
    sereignarsparnadur: gettext("Séreignarsparnaður"),
    aldur: gettext("Aldur"),
    manadarlaun: gettext("Mánaðarlaun"),
    utborgun: gettext("Útborgun"),
    utborgunartimi: gettext("Útborgunartími"),
    inneign: gettext("Inneign"),
    aaetlud_raunavoxtun: gettext("Áætluð raunávöxtun"),
    reikna: gettext("Reikna"),
    afrita_slod: gettext("Afrita slóð á niðurstöður"),
    disclaimer: gettext("Útreikningar miðast við ofangreindar forsendur. ATH þetta er eingöngu til viðmiðunar og ekki er um endanlegan útreikning að ræða."),
    disclaimber_button_text: gettext("Fyrirvari"),
    monthly_tooltip_label: (dataSeries, yValue) => interpolate(
        gettext("%s: %s kr. á mánuði"),
        [dataSeries, yValue]
    ),
    pie_percentage_label: (amount, percentage) => interpolate(
        gettext("%s kr. (%s%)"),
        [amount, percentage]
    ),
    heildarframlag_special_savings_subtitle: gettext("heildarframlag (samtals framl. launþega og launagreiðanda)"),
}